import { SECTION_CATEGORY } from '@wix/communities-blog-client-common';
import { getEntityCount, getPaginationPageSize } from '../../selectors/pagination-selectors';
import { getTagListItems } from '../get-tag-list-items';
import { getTagUrl } from '../get-tag-url';
import { createTagPageUrl } from '../navigation';
import { getLastPage } from '../pagination';
import { getPaginationUrls } from './get-pagination-item-data';

export const generateTagSEOTags = ({ sectionUrl, postPageSectionUrl, tag, state, page, posts }) => {
  const tagUrl = getTagUrl(sectionUrl, tag.slug);

  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);

  const pagination = {
    ...getPaginationUrls(tagUrl, page, lastPage, state),
    totalPages: parseInt(Math.ceil(entityCount / pageSize), 10),
    currentPage: page,
  };

  const itemData = {
    name: tag.label,
    title: tag.label,
    fullUrl: createTagPageUrl(sectionUrl, tag.slug, page),
    listItems: getTagListItems(posts, postPageSectionUrl),
    items: {
      numberOfItems: posts.length,
    },
    created: tag.createdDate,
    postCount: tag.postCount,
    language: tag.language,
    pagination,
  };

  return {
    itemType: 'BLOG_TAGS', // constant is not provided by advanced-seo-utils
    itemData: { tag: itemData },
  };
};
